export const clientEndPoint = {
    GET_ALL: '/api/client/all',
    GET_ALL_QUOTES: '/api/client/quotes',
    GET_ALL_ROLE_DROPDOWN_VALUES: '/api/role/all',
    ACCEPT_TIMELINE: '/api/vendor/acceptTimeline',
    GET_CLIENT_DETAILS: '/api/client/details',
    CLIENT_CREATE_VENDOR: '/auth/client/addVendor',
    CLIENT_CREATE_EMPLOYEE: '/api/employee/create',
    CREATE_EMPLOYEE_QUESTIONNAIRE: '/api/employeeQuestionnaire/create',
    EMPLOYEE_GET_QUESTIONNAIRE: '/api/employeeQuestionnaire/all',
    EMAIL_MESSAGE_LIST: '/api/email-questionnaire/all',
    EMPLOYEE_GET_QUESTIONNAIRE_DETAILS: '/api/employeeQuestionnaire/details',
    SEND_EMAIL_MESSAGE: '/api/send-email/template-email',
    CLIENT_CREATE_WORK_PERMIT: '/api/workPermit/create',
    CLIENT_REJECT_WORK_PERMIT: '/api/workPermit/reject',
    CLIENT_APPROVE_WORK_PERMIT: '/api/workPermit/approve',
    CLIENT_GET_WORK_PERMIT: '/api/workPermit/all',
    VENDOR_FIND_ALL_GET_WORK_PERMIT: '/api/workPermit/vendor/all',
    WORK_PERMIT_LOTO_LIST: '/api/loto/lotoByWorkPermit',
    LOTO_BY_CLIENT: '/api/loto/lotoByClient',
    CLIENT_GET_EMPLOYEE: '/api/employee/all',
    CLIENT_GET_EMPLOYEE_DETAILS: '/api/employee/details',
    EMPLOYEE_MEDICAL_LIST: 'api/employeeMedical/all',
    EMPLOYEE_MEDICAL: 'api/employeeMedical/create',
    EMPLOYEE_MEDICAL_DETAILS: 'api/employeeMedical/details',
    REJECT_TIMELINE: '/api/vendor/rejectTimeline',
    UPLOAD_FILE: '/api/project/upload',
    PROJECT_DOCUMENTS: '/api/project/documents/all',
    DOWNLOAD_FILE: '/api/project/documents',
    BIDDING_DETAILS: '/api/project/biddingDetails',
    AWARD_PROJECT: '/api/project/award',
    SEND_EMAIL: '/api/project/send-email?projectId=',
    SUBMIT_BID: '/api/project/submitBid',
    ONBOARDING_CODE : "/api/client/onboarding-code",
}
